import {createApp} from 'vue'
import KanalK from "@/KanalK";

/**
 * Global Functions
 */
//Email Validation
export function globalValidateEmail(email) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false
}

//Number Validation
export function globalValidateNumber(string) {
    return !isNaN(string)
}

//Float Number To Time
export function globalNumberToTime(number) {
    if (isNaN(number)) {
        number = 0;
    }
    let timeString = new Date(number * 1000).toISOString();
    if (number < 3600) {
        return timeString.substring(14, 19)
    } else {
        return timeString.substring(11, 19)
    }
}

//Get URL Protocol
export function globalGetUrlProtocol(href) {
    let url = new URL(href);
    return url.protocol;
}

//Is URL Same Origin as This Web-App? Same Host? Is the HREF internal?
export function globalIsInternalHref(href) {
    const url = new URL(href);
    //If it's download, do not handle href as internal
    if (url.pathname.startsWith('/wp-content/')) {
        return false;
    }
    else if (
        url.host == 'localhost:8080' ||
        url.host == 'www.kanalk.ch' ||
        url.host == 'kanalk.ch' ||
        url.host == 'dev.kanalk.ch'
    ) {
        return true;
    }
    return false
}

//Make Relative Href from Absolute
export function globalRelativizeHref(href) {
    const url = new URL(href);
    return url.pathname;
}

//Make Sure Dash is at the end of an url Path
export function globalDashPath(path) {
    if(path.slice(-1) != '/') {
        return path + '/';
    }
    return path;
}

//Make Sure NO Dash is at the end of an url Path
export function globalNoDashPath(path) {
    if(path.slice(-1) == '/') {
        return path.slice(0, -1);
    }
    return path;
}

//Image Preload
export async function globalImagePreload(imageUrl) {
    if(imageUrl) {
        var image = new Image();
        image.src = imageUrl;
        image.onload = function () {
            return imageUrl;
        }
    }
    return '';
}

export async function globalAudioDuration(audioSrc) {
    return new Promise(function(resolve) {
        let audio = new Audio();
        audio.addEventListener("loadedmetadata", function(){
            resolve(audio.duration);
        });
        audio.src = audioSrc;
    });
}

//normalize Strings for Better Search Results (Remove Special Chars)
export function globalNormalizeString(string) {
    return string
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/ /g, ")(?=.*");
}

/**
 * Create App
 */
const kanalk = createApp(KanalK);

/**
 * Custom Global Directives
 */
// v-on-click-outside => click outside of element
// import onClickOutside from "@/_directives/on-click-outside";
// kanalk.directive('on-click-outside', onClickOutside);


/**
 * Use Plugins & other helpful Stuff
 */
//Vue Router
import router from '@/router/router'
kanalk.use(router);

//Vuex Store (Global Stores)
import {store} from "@/store/store";
kanalk.use(store);

//Loading Progressbar
import {Vue3ProgressPlugin} from '@marcoschulte/vue3-progress';
kanalk.use(Vue3ProgressPlugin);  //Loading indicator

//TODO
//Re Captcha
// import { VueReCaptcha } from 'vue-recaptcha-v3'
// kanalk.use(VueReCaptcha, {siteKey: '6Ld0CQ8iAAAAADtp2Q5a6AinvFmPYy3xqMjCe5s-'})

//Media Queries // Responsive
import {Vue3Mq} from "vue3-mq";
kanalk.use(Vue3Mq, { preset: 'bootstrap5' });

//Google Analytics
//OFF because app is not active anymore
/*
import VueGtagPlugin from "vue-gtag";
kanalk.use(VueGtagPlugin, {
    appName: 'kanalk-2022-vue',
    config: {
        id: "G-ZS86KS94M7",
        params: {
            anonymize_ip: true
        }
    },
}, router); //Google Analytics
 */

//Vue3 Cookie Management
import { globalCookiesConfig } from "vue3-cookies";
import VueCookies from 'vue3-cookies'
globalCookiesConfig({
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None",
});
kanalk.use(VueCookies);



/**
 * Mount App
 */
kanalk.mount('#kanalk');
